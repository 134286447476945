<template>
    <div>
      <b-modal
        id="modal-update-infeksi-menular-seksual"
        size="xl"
        centered
        title="Update Infeksi Menular Seksual (IMS)"
        header-bg-variant="primary"
        header-text-variant="light"
        @show="openModal()"
      >
        <div class="">
          <b-row class="m-0">
            <b-col cols="12" md="12" lg="12">
              <b-form-group label-cols-md="4">
                <template v-slot:label>
                  Bulan - Tahun
                </template>
                <date-picker
                  style="width: 100%"
                  id="tanggal"
                  format="MMMM - YYYY"
                  type="month"
                  v-model="dataForm.tanggal"
                  :disabled="onUpdate"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6" lg="6">
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Puskesmas Pembantu yang ada
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_pustu"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Poskesdes/Bidan di Desa
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_poskesdes"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6" lg="6">
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Puskesmas Pembantu yang lapor
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_pustu_lapor"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Poskesdes/Bidan yang lapor
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_poskesdes_lapor"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <b-table-simple responsive class="table-lap-pelayanan-harian">
                <b-thead>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                    <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                    <b-th colspan="9" style="min-width: 100px;">Kelompok Resiko</b-th>
                  </b-tr>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">WPS</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">PPS</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Waria</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">LSL</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">IDU</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Pasangan Risti</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Pelanggan PS</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Lain-lain</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                  </b-tr>
                </b-thead>
                
                <b-tbody v-if="dataForm.data_ims">
                  <b-tr v-for="(value, key, i) in is_real['Utama']" :key="key">
                    <b-td class="text-center">{{ i+1 }}</b-td>
                    <b-td>{{ key }}</b-td>
                    <b-td v-for="(value2, key2) in is_real['Utama'][key].laki_laki" :key="'01'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Utama'][key].laki_laki.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Utama'][key].laki_laki[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td v-for="(value2, key2) in is_real['Utama'][key].perempuan" :key="'02'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Utama'][key].perempuan.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Utama'][key].perempuan[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td class="text-center"><b>{{ dataForm.data_ims['Utama'][key].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in is_real['Utama'][key].kelompok_resiko" :key="'03'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Utama'][key].kelompok_resiko.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Utama'][key].kelompok_resiko[key2]" @input="countData()"></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                    <b-td v-for="(value2, key2) in total['Utama'].laki_laki" :key="'01'+key2">
                      <div class="text-center"><b>{{ total['Utama'].laki_laki[key2] }}</b></div>
                    </b-td>
                    <b-td v-for="(value2, key2) in total['Utama'].perempuan" :key="'02'+key2">
                      <div class="text-center"><b>{{ total['Utama'].perempuan[key2] }}</b></div>
                    </b-td>
                    <b-td class="text-center"><b>{{ total['Utama'].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in total['Utama'].kelompok_resiko" :key="'03'+key2">
                      <div class="text-center"><b>{{ total['Utama'].kelompok_resiko[key2] }}</b></div>
                    </b-td>
                  </b-tr>
                  <tr>
                    <b-td colspan="29">
                      <h6 style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                        Pendekatan Sindrom / Klinis
                      </h6>
                    </b-td>
                  </tr>
                  <b-tr v-for="(value, key, i) in is_real['Pendekatan Sindrom / Klinis']" :key="key">
                    <b-td class="text-center">{{ i+1 }}</b-td>
                    <b-td>{{ key }}</b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].laki_laki" :key="'01'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].laki_laki.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].laki_laki[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].perempuan" :key="'02'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].perempuan.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].perempuan[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td class="text-center"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Sindrom / Klinis'][key].kelompok_resiko" :key="'03'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].kelompok_resiko.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Sindrom / Klinis'][key].kelompok_resiko[key2]" @input="countData()"></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].laki_laki" :key="'01'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Sindrom / Klinis'].laki_laki[key2] }}</b></div>
                    </b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].perempuan" :key="'02'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Sindrom / Klinis'].perempuan[key2] }}</b></div>
                    </b-td>
                    <b-td class="text-center"><b>{{ total['Pendekatan Sindrom / Klinis'].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Sindrom / Klinis'].kelompok_resiko" :key="'03'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Sindrom / Klinis'].kelompok_resiko[key2] }}</b></div>
                    </b-td>
                  </b-tr>
                  <tr>
                    <b-td colspan="29">
                      <h6 style="font-weight: 700; color: #9c4098; font-size: 16px; margin-bottom: 0">
                        Pendekatan Laboratorium
                      </h6>
                    </b-td>
                  </tr>
                  <b-tr v-for="(value, key, i) in is_real['Pendekatan Laboratorium']" :key="key">
                    <b-td class="text-center">{{ i+1 }}</b-td>
                    <b-td>{{ key }}</b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].laki_laki" :key="'01'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].laki_laki.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Laboratorium'][key].laki_laki[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].perempuan" :key="'02'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].perempuan.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Laboratorium'][key].perempuan[key2]" @input="countData()"></b-form-input>
                    </b-td>
                    <b-td class="text-center"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in is_real['Pendekatan Laboratorium'][key].kelompok_resiko" :key="'03'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_ims['Pendekatan Laboratorium'][key].kelompok_resiko.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_ims['Pendekatan Laboratorium'][key].kelompok_resiko[key2]" @input="countData()"></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Laboratorium'].laki_laki" :key="'01'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Laboratorium'].laki_laki[key2] }}</b></div>
                    </b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Laboratorium'].perempuan" :key="'02'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Laboratorium'].perempuan[key2] }}</b></div>
                    </b-td>
                    <b-td class="text-center"><b>{{ total['Pendekatan Laboratorium'].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in total['Pendekatan Laboratorium'].kelompok_resiko" :key="'03'+key2">
                      <div class="text-center"><b>{{ total['Pendekatan Laboratorium'].kelompok_resiko[key2] }}</b></div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-update-infeksi-menular-seksual')">
            Batal
          </b-button>
          <b-button
            v-if="onUpdate"
            variant="warning"
            @click="update()"
            >Update</b-button
          >
          <b-button
            variant="primary"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  export default {
    name: "modalInput",
    props: [
      'is_data', 
      'is_real', 
      'onUpdate', 
      'dataSearch'
    ],
    data() {
      return {
        dataForm: {},
        total: {},
        busy: false,
        button: "Simpan",
      };
    },
    watch: {
    },
    methods: {
      async openModal(){
        const vm = this
        // await new Promise(resolve => setTimeout(resolve, 10));
        const dataForm = JSON.parse(JSON.stringify(vm.is_data))
        dataForm.tanggal = new Date(vm.dataSearch.tanggal_awal)
        if(!vm.onUpdate){
          dataForm.data_ims = vm.is_real
        }
        vm.dataForm = dataForm
        console.log(vm.is_data)
        vm.countData()
        vm.reset()
      },
      // menghitung row / baris
      async countData(){
        const vm = this
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_ims))
        for (const key1 in data) {
          for (const key2 in data[key1]) {
            let total_lp = 0 // total nilai dari laki laki dan perempuan per row / baris
            for (const key3 in data[key1][key2]) {
              if(key3 != 'total_lp'){
                // menghitung nilai dari laki laki / perempuan / kelompok resiko per row / baris
                let jumlah = 0
                for (const key4 in data[key1][key2][key3]) {
                  data[key1][key2][key3][key4] = Number.parseInt(data[key1][key2][key3][key4])
                  if(key4 != 'jumlah'){
                    jumlah +=  data[key1][key2][key3][key4]
                  }
                }
                data[key1][key2][key3].jumlah = jumlah // masukkan nilai dari laki laki / perempuan / kelompok resiko per row / baris
                // menghitung total nilai dari laki laki dan perempuan resiko per row / baris
                if(key3 == 'laki_laki' || key3 == 'perempuan'){
                  total_lp += jumlah
                }
              }
            }
            data[key1][key2].total_lp = total_lp // masukkan total nilai dari laki laki dan perempuan resiko per row / baris
          }
        }
        vm.dataForm.data_ims =  JSON.parse(JSON.stringify(data))
        vm.countTotal()
      },
      // menghitung column / kolom
      async countTotal(){
        const vm = this
        const total = {}
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_ims))
        for (const key1 in data) {
          if(!total[key1]){
            total[key1] = {}
          }
          for (const key2 in data[key1]) {
            for (const key3 in data[key1][key2]) {
              if(total[key1][key3] === undefined) {
                if(key3 == 'total_lp'){
                  total[key1][key3] = 0
                }else{
                  total[key1][key3] = {}
                }
              }
              if(key3 == 'total_lp'){
                if(total[key1][key3] === undefined || total[key1][key3] == {}) {
                  total[key1][key3] = Number.parseInt(data[key1][key2][key3])
                }else{
                  total[key1][key3] += Number.parseInt(data[key1][key2][key3])
                }
              }else{
                for (const key4 in data[key1][key2][key3]) {
                  if(!total[key1][key3][key4]) {
                    total[key1][key3][key4] = Number.parseInt(data[key1][key2][key3][key4])
                  }else{
                    total[key1][key3][key4] += Number.parseInt(data[key1][key2][key3][key4])
                  }
                }
              }
            }
          } 
        }
        vm.total = total
      },
      async simpan(){
        const vm = this
        vm.busy = true
        try {
          //ubah value menjadi number
          let data_ims = JSON.parse(JSON.stringify(vm.dataForm.data_ims))
          for (const key1 in data_ims) {
            for (const key2 in data_ims[key1]) {
              for (const key3 in data_ims[key1][key2]) {
                for (const key4 in data_ims[key1][key2][key3]) {
                  data_ims[key1][key2][key3][key4] = Number.parseInt(data_ims[key1][key2][key3][key4])
                }
              }
            }
          }
          let res = await vm.$axios.post('/pemeriksaan_ims/register', {
            ...vm.dataForm,
            tahun_ims: vm.$moment(vm.dataForm.tanggal).format('YYYY'),
            bulan_ims: vm.$moment(vm.dataForm.tanggal).format('MM'),
            data_ims,
          })
          // console.log('register kegiatan_kelompok', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Pemeriksaan IMS', showing: true });
            vm.$bvModal.hide('modal-update-infeksi-menular-seksual')
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      async update(){
        const vm = this
        vm.busy = true
        try {
          //ubah value menjadi number
          let data_ims = JSON.parse(JSON.stringify(vm.dataForm.data_ims))
          for (const key1 in data_ims) {
            for (const key2 in data_ims[key1]) {
              for (const key3 in data_ims[key1][key2]) {
                for (const key4 in data_ims[key1][key2][key3]) {
                  data_ims[key1][key2][key3][key4] = Number.parseInt(data_ims[key1][key2][key3][key4])
                }
              }
            }
          }
          let res = await vm.$axios.post('/pemeriksaan_ims/update', {
            ...vm.dataForm,
            tahun_ims: vm.$moment(vm.dataForm.tanggal).format('YYYY'),
            bulan_ims: vm.$moment(vm.dataForm.tanggal).format('MM'),
            data_ims,
          })
          // console.log('update kegiatan_kelompok', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Mengupdate Pemeriksaan IMS', showing: true });
            vm.$bvModal.hide('modal-update-infeksi-menular-seksual')
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      reset() {
        const vm = this
        vm.data = {
          // edu_id: null,
          kode_club: null,
          tanggal_pelayanan: vm.tanggal_sesi,
          kode_kegiatan: null,
          kode_kelompok: null,
          materi: null,
          pembicara: null,
          lokasi: null,
          keterangan: null,
          biaya: null,
        };
      },
    },
  };
</script>
  
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>